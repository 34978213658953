@use './variables.scss' as var;
.navbar-brand{
    position:relative;
    padding:0 20px;
    z-index:0;
    &::after {
        content:'';
        position:absolute;
        z-index: -1;
        background: var.$logo-color;
        top:0;
        bottom:0;
        left:0;
        right:0;
        border-radius:100% 0 100% 0;
    }
}