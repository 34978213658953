.resetpassword,.register,.login {text-align:center;}

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.google-btn {
  width: 184px;
  height: 42px;
  background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  display:flex;
  justify-content:space-between;
  margin:auto;
  padding:1px;
  align-items:center;
  border:0;
  .google-icon-wrapper {
    
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $white;
    display: flex;
    justify-content:center;
    align-items:center;
  }
  .google-icon {
    width: 18px;
    height: 18px;
  }
  .btn-text {
    flex:1;
    margin: 0;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }
  &:active {
    background: $button-active-blue;
  }
}

@import url("https://fonts.googleapis.com/css?family=Roboto:500");