@use './variables.scss' as var;
@use "./header.scss";
@import './bootstrap-overrides.scss';
@import './3rdparty/bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.correct {background:#66ff66!important;}
.incorrect {background: #ff6666!important;}
.slaydle {text-align:center;}

.slaydle th { text-transform:capitalize; }
.datepicker-container {
  position: relative;
  display: inline-block;
}
.react-datepicker__tab-loop {
  position:absolute;

}
.row {
  margin-bottom:10px;
}
.content-container {
  max-width:1200px;
  margin:auto;
  display:flex;
  justify-content: center;
  padding-bottom:10px;

}
.button-container, .datepicker-container {
  padding-left:5px;
  padding-right:5px;
}
#main-container{
  display: grid;
  height:100vh;
  grid-template-columns: 200px 50px auto auto;
  grid-template-rows: 56px auto;
  grid-template-areas: 
    "header header header header"
    "main main main main";

}
#header {
  grid-area:header;
  background:#88aaaa;
  z-index:100;
}

#sidebar {
  display: none;
  grid-area:sidebar;
  background:#88cccc;
  padding:10px;
  overflow: auto;
}
#detail {
  grid-area:main;
  
  background:var.$bg;
  overflow: auto;
  padding:var.$padding;
}
#footer {
  grid-area:footer;
  background:#88aaaa;
}

.narrow {
  max-width:600px;
  margin:auto;
}
.center-content{}

.has-drag-handle{
  display: flex;
  // justify-content;
  >div{
    flex: 1 0 auto;
  }
  
  .drag-handle{
    flex: 0 1 10px;
  }
}
.tasklist-sidebar{
  // padding:var.$padding;
}
.tasklist-area{
  &.multicolumn{
    display:flex;
    overflow-x:auto;
    min-height:70vh;
  }
}

.sortable-column{
  min-width:220px;
  padding:10px;
  background: var.$bg-column;
  
  border-radius:5px;
  
  &:nth-child(n+2){
    margin-left:5px;

  }
}
.task-card{
  width:200px;
  margin-bottom:5px;
  border:none;
  .card-title input{
    margin-right:5px;
  }
}
.col-drop-area{

  height:20px;
}
.new-column {
  min-width:200px;
  padding:10px;
  background:var.$bg-column;
  margin-left:5px;
  margin-right:5px;
  color: var.$text;
  font-size: 40px;
  border:none;
}