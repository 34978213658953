$color-lightpink: #edd;
$color-lightlightpink: #fee;
$color-lightred: #e82b3d;
$color-red: #b92331;
$color-darkred:#6d1820;
$color-lightgrey: #ccc;

$logo-color:$color-lightred;
$bg: $color-lightpink;
$bg-column: $color-lightlightpink;

$text: $color-darkred;
$font-family-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;


$padding:10px;
$margin:10px;
